import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			imagesForModal: []
		};
	},
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				//unitgroupid: 25551
			},
			sorting: 'random',
			max: 3
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	methods: {
		showImagesModal: function (images, uniqueId = '') {
			if (images && images.length > 1) {
				this.imagesForModal = images;
				//$('#unitImages').modal('show');
				this.showCustomModal('unitImages' + uniqueId);

			} else {
				this.imagesForModal = [];
			}
		},
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";
					body.classList.add('modal-open');


					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			if (modal) {
				modal.style.display = "none";
				body.classList.remove('modal-open');
			}

		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;

		},

		myEventHandler(e) {
			V.log(window.innerWidth);
		}
	}

};